import React from 'react';
import './Widgets.css';

function Widgets() {
    return (
        <div className='widgets'>
            <iframe  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDiploman-110754074994396%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
             title="Iframe title"
             width="340" 
             height="500vh" 
             //orignal height is 100vh but I changed it becuse I use Widget inside a div
             style={{ border: 'none', overflow: 'hidden' }} 
             scrolling="no" 
             frameBorder="0" 
             allowtransparency="true" 
             allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
        </div>
    )
}

export default Widgets;